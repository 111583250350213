import './css/main.css';
// import './js/isotope.js';
// import './js/gallery.js';

// Optional. When using fullPage extensions
// import scrollOverflowReset from './fullpage.scrollOverflowReset.min';

// Optional. When using scrollOverflow:true
// import IScroll from 'fullpage.js/vendors/scrolloverflow';

// Importing fullpage.js
// import fullpage from 'fullpage.js';

import './js/fullpage.scrollOverflowReset.min.js';

// When using fullPage extensions replace the previous import
// of fullpage.js for this file
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

// Initializing it
var fullPageInstance = new fullpage('#myFullpage', {
  licenseKey: 'QNXU7-JVUV6-FJ48I-SJFI7-NQVVJ',
  navigation: true,
  //   sectionsColor: ['#ff5f45', '#0798ec', '#fc6c7c'],
  scrollBar: false,
  scrollOverflow: true,
  scrollOverflowReset: true,
  scrollOverflowResetKey: 'c3RlcC1wcm9tb3RlLnN0dWR5XzFPRWMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT1iOGo='

  // navigation: true
});
